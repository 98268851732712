<script setup lang="ts">
interface propType {
  data: {
    id: number
    image: string
    color_mode: 'light' | 'dark'
    translations: DirectusTranslation<{
      quote: string
      name: string
      function?: string
    }>
  }
}

const props = defineProps<propType>()
</script>

<template>
  <BlockBase
    :is-light="props.data.color_mode === 'light'"
    class="block-light:bg-neutral-white signature-page:bg-signature-black block-light:text-neutral-black signature-page:block-light:bg-signature-darkGray signature-page:block-light:text-neutral-white"
  >
    <Container class="flex items-center justify-center py-12 lg:py-15rem">
      <div class="grid grid-cols-[min-content_1fr] items-center justify-center gap-8 lg:flex-row lg:items-center lg:gap-3rem">
        <div class="size-62px lg:size-143px">
          <NuxtImg
            loading="lazy" provider="directus" :src="props.data.image" :height="143" :width="143"
            class="size-full object-cover"
          />
        </div>
        <h3 class="order-last col-span-2 max-w-full text-balance text-2rem font-title md:order-none md:col-span-1 md:max-w-45rem lg:text-2.5rem">
          “{{ dt(props.data.translations)?.quote }}”
        </h3>
        <div class="mt-1rem text-1rem text-neutral-gray tracking-4px uppercase md:col-span-2 lg:text-1.3rem md:tracking-3px">
          <p
            :class="{ 'font-bold': dt(props.data.translations)?.function }"
          >
            {{ dt(props.data.translations)?.name }}
          </p>
          <p class="tracking-3px">
            {{ dt(props.data.translations)?.function }}
          </p>
        </div>
      </div>
    </Container>
  </BlockBase>
</template>

<style></style>
