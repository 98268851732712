<script setup lang="ts">
import { useKeenSlider } from 'keen-slider/vue'
import 'keen-slider/keen-slider.min.css'

const props = defineProps<{
  data: {
    id: string
    items: {
      block_carousel_manual_item_id: {
        id: number
        image: string
        link: string
        translations: DirectusTranslation<{
          text: string
          subtext: string
        }>
      }
    }[]
  }
}>()

const [container, slider] = useKeenSlider({
  loop: true,
  renderMode: 'performance',
  drag: true,
  slides: {
    perView: 1,
    spacing: 30,
  },
  breakpoints: {
    '(min-width: 1024px)': {
      slides: {
        perView: 1.7,
        spacing: 30,
      },
    },
    '(min-width: 1280px)': {
      slides: {
        perView: 2.7,
        spacing: 30,
      },
    },
  },
})

function nextSlide() {
  slider?.value?.next()
}
function prevSlide() {
  slider?.value?.prev()
}
</script>

<template>
  <BlockBase class="py-4rem lg:py-20rem">
    <!-- <pre>{{ props.data }}</pre> -->
    <Container class="w-full flex flex-col-reverse justify-between gap-10rem lg:flex-row">
      <!-- SLIDER -->
      <div ref="container" class="keen-slider">
        <!-- Slide -->
        <template v-for="item in props.data.items" :key="item.id">
          <NuxtLinkLocale :to="item.block_carousel_manual_item_id.link" class="keen-slider__slide">
            <div class="absolute left-0 top-0 z-20 h-85px w-full flex">
              <div class="relative z-10 h-full w-fit bg-starline-secondary p-2rem signature-page:!bg-signature-black">
                <p class="text-1.3rem text-neutral-white tracking-3px uppercase">
                  {{ dt(item.block_carousel_manual_item_id.translations)?.text }}
                </p>
                <p class="text-1.6rem text-neutral-gray font-title">
                  {{ dt(item.block_carousel_manual_item_id.translations)?.subtext }}
                </p>
              </div>
              <div class="slideout absolute left-0 top-0 z-0 h-full w-full flex items-start justify-center gap-2rem bg-starline-secondary p-2rem signature-page:bg-signature-black">
                <div class="relative top-8px h-1px w-full bg-neutral-white" />
                <IconArrowRight class="h-18px w-18px text-neutral-white" />
              </div>
            </div>
            <NuxtImg
              loading="lazy"
              provider="directus"
              :src="item.block_carousel_manual_item_id.image"
              alt=""
              height="500"
              width="500"
              class="block h-full w-full object-cover"
            />
            <!-- <NuxtImg
              provider="directus"
              :src="item.block_carousel_manual_item_id.image"
              class="h-full w-full object-cover"
              height="650"
            /> -->
            <!-- <img class="relative z-10 h-full w-full object-cover" src="/images/home/home-slider-placeholder.jpg" alt="image"> -->
            <div class="slide-box-shadow" />
          </NuxtLinkLocale>
        </template>

        <!-- Controls -->
        <div class="absolute bottom-0 right-0 h-10rem w-15rem flex items-center justify-center bg-starline-secondary text-neutral-white xl:bottom-3rem signature-page:bg-signature-black">
          <button class="h-10rem w-7.5rem flex items-center justify-center hover:bg-neutral-black/20" @click="prevSlide">
            <IconSmallArrow class="h-2rem w-2rem fill-current" />
          </button>
          <button class="h-10rem w-7.5rem flex items-center justify-center hover:bg-neutral-black/20" @click="nextSlide">
            <IconSmallArrow class="h-2rem w-2rem rotate-180 fill-current" />
          </button>
        </div>
      </div>
    </Container>
  </BlockBase>
</template>

<style scoped lang="scss">
.keen-slider {
  @apply right-3rem h-56rem p-3rem;

  @media only screen and (max-width: 1280px) {
    @apply right-0 h-50rem p-0rem;
  }

  &__slide {
    overflow: visible !important;
    @apply relative;

    .slide-box-shadow {
      @apply absolute overflow-visible inset-0 z-0 h-full w-full transition duration-500;
    }

    &:hover {
      .slide-box-shadow {
        box-shadow: 0px 0px 30px 0px rgba(109, 170, 221, 0.6);
      }

      .slideout {
        @apply scale-x-100;
      }
    }
  }

  .slideout {
    @apply scale-x-0 transition origin-left duration-500 ease-in-out;
  }
}
[data-is-signature-page='true'] .keen-slider__slide:hover .slide-box-shadow {
  box-shadow: 0px 0px 30px 0px #ccad6e99 !important;
}
</style>
