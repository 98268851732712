<script setup lang="ts">
const props = defineProps<{
  link: string
  text: string
  arrow?: boolean
  light?: boolean
}>()
</script>

<template>
  <NuxtLinkLocale
    :to="props.link"
    class="btn-arrow w-full flex items-center justify-center gap-0.5rem border px-2.5rem py-1rem md:w-fit"
    :class="props.light ? 'border-starline-secondary bg-neutral-white text-starline-secondary' : 'border-neutral-white bg-starline-secondary signature-page:bg-signature-black text-neutral-white'"
  >
    <p
      class="text-1.3rem tracking-4px uppercase"
      :class="props.light ? 'text-starline-secondary' : 'text-neutral-white'"
    >
      {{ props.text }}
    </p>
    <IconArrowRight class="btn-arrow-icon size-2.2rem text-current transition duration-300" />
  </NuxtLinkLocale>
</template>

<style scoped>
.btn-arrow:hover .btn-arrow-icon {
  @apply translate-x-5px;
}

.text-no-wrap {
  white-space: nowrap;
}
</style>
