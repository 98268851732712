<script setup lang="ts">
import { useKeenSlider } from 'keen-slider/vue'
import 'keen-slider/keen-slider.min.css'

const props = defineProps<{
  data: {
    type: 'image_text' | 'text' | 'text_image'
    color_mode: 'light' | 'dark'
    translations: DirectusTranslation<{
      title: string
      text: string
      divider_text: string
      buttons: {
        text: string
        url: string
      }[]
    }>
    images: {
      directus_files_id: DirectusFile
    }[]
  }
}>()

const [container, slider] = useKeenSlider({
  loop: true,
  renderMode: 'performance',
  drag: true,
  slides: {
    perView: 1,
  },
})

function nextSlide() {
  slider?.value?.next()
}
function prevSlide() {
  slider?.value?.prev()
}
</script>

<template>
  <BlockBase :is-light="props.data.color_mode === 'light'" class="bg-starline-secondary py-6rem block-light:bg-neutral-white signature-page:bg-signature-black lg:py-12rem block-light:text-neutral-black signature-page:block-light:bg-signature-darkGray signature-page:block-light:text-neutral-white">
    <Container class="grid grid-cols-1 w-full gap-4rem lg:grid-cols-2 lg:gap-10rem">
      <!-- SLIDER -->
      <div
        v-if="props.data.images.length && props.data.type !== 'text'" ref="container" class="keen-slider my-auto h-36rem w-full lg:h-65rem"
        :class="{
          'lg:order-last': props.data.type === 'text_image',
        }"
      >
        <div v-for="image, index in props.data.images" :key="index" class="keen-slider__slide size-full">
          <NuxtImg
            v-if="image.directus_files_id"
            loading="lazy"
            provider="directus"
            height="650"
            width="650"
            :src="image.directus_files_id.id"
            :alt="image.directus_files_id.description"
            class="h-full w-full object-cover"
          />
        </div>

        <div v-if="props.data.images.length > 1" class="absolute bottom-0 right-0 flex items-center justify-center bg-starline-secondary lg:h-10rem lg:w-15rem block-light:bg-neutral-white block-light:signature-page:bg-signature-darkGray">
          <button class="h-8rem w-6rem flex items-center justify-center transition lg:h-10rem lg:w-7.5rem hover:bg-neutral-black/10 block-light:hover:bg-starline-secondary/10 block-light:signature-page:hover:bg-signature/20" @click="prevSlide">
            <IconSmallArrow class="h-2rem w-2rem" />
          </button>
          <button class="h-8rem w-6rem flex items-center justify-center transition lg:h-10rem lg:w-7.5rem hover:bg-neutral-black/10 block-light:hover:bg-starline-secondary/10 block-light:signature-page:hover:bg-signature/20" @click="nextSlide">
            <IconSmallArrow class="h-2rem w-2rem rotate-180" />
          </button>
        </div>
      </div>

      <!-- Content -->
      <div
        class="my-auto w-full"
        :class="{
          'col-span-2 mx-auto flex': props.data.type === 'text',
        }"
      >
        <div
          :class="{
            'mx-auto text-center flex flex-col items-center justify-center w-full': props.data.type === 'text',
          }"
        >
          <TextWithAfterLine v-if="dt(props.data.translations)?.divider_text" class="mb-2rem" :text="dt(props.data.translations)?.divider_text ?? ''" />
          <h1
            class="text-balance text-4rem font-title lg:mb-4rem md:text-7.6rem"
          >
            {{ hyphen(dt(props.data.translations)?.title) }}
          </h1>

          <div class="mb-3.5rem text-1.6rem prose" v-html="dt(props.data.translations)?.text ?? ''" />
          <div
            class="w-full flex flex-wrap items-center justify-center gap-0.8rem"
            :class="props.data.type !== 'text' ? 'md:justify-start' : ''"
          >
            <BtnArrow v-for="button, index in dt(props.data.translations)?.buttons" :key="index" class="block" :link="button.url" :text="button.text" :light="props.data.color_mode === 'light'" />
          </div>
        </div>
      </div>
    </Container>
  </BlockBase>
</template>

<style scoped>
/* .keen-slider {
} */
</style>
